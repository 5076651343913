import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import {StaticImage} from 'gatsby-plugin-image'
import Text from '../../../components/Text'
import Title from '../../../components/Title'
import FourOutOfFive from '../../../assets/images/svgs/four-out-of-five.inline.svg'

import {
  whyJoinTheFightSection,
  heading,
  bgImage,
} from './whyjointhefight.module.scss'

interface ContentDataModel {
  type: 'text' | 'heading'
  text: string
}

interface SectionDataModel {
  title: string
  content: Array<ContentDataModel>
}

const data: SectionDataModel = {
  title: 'WHY JOIN THE FIGHT AGAINST CERTAIN HPV CANCERS?',
  content: [
    {
      type: 'text',
      text: 'Although most HPV (human papillomavirus) infections go away on their own, some types of HPV may cause infections that increase the risk of certain cancers and pre-cancerous cell changes.',
    },
    {
      type: 'text',
      text: 'The HPV vaccination programme is available on the NHS in schools so that boys and girls can join the fight against certain types of HPV cancers, like anal cancer and cervical cancer.',
    },
    {
      type: 'heading',
      text: 'HPV means human papillomavirus',
    },
    {
      type: 'text',
      text: 'It’s the name for a group of viruses that may cause diseases in adulthood, like certain cancers and pre-cancerous cell changes.',
    },
    {
      type: 'heading',
      text: 'HPV is common and spreads easily',
    },
    {
      type: 'text',
      text: 'The viruses move from person to person through close skin-to-skin contact.',
    },
  ],
}

const WhyJoinTheFight: React.FC = () => {
  return (
    <section id="WhyJoinTheFight" className={whyJoinTheFightSection}>
      <Container>
        <Row className="pt-5 px-4">
          <Col
            lg={6}
            md={12}
            className={`${bgImage} text-center order-last order-lg-first align-self-end`}
          >
            <FourOutOfFive style={{height: '30%'}} />

            <StaticImage
              layout="fullWidth"
              alt="A image of boy and girl supporting HPV vaccination"
              src={'../../../assets/images/pngs/HPV-Boy-and-Girl-2.png'}
            />
          </Col>
          <Col lg={6} md={12} className="px-lg-5 pb-4">
            <Title color="#400099" size="2rem">
              <h2 className="text-start">{data.title}</h2>
            </Title>
            {data.content.map((item: ContentDataModel, i: number) => {
              if (item.type === 'text') {
                return (
                  <Text key={i} color="#4e4e4e">
                    {item.text}
                  </Text>
                )
              }
              return (
                <h3 key={i} className={`${heading} text-start`}>
                  {' '}
                  {item.text}
                </h3>
              )
            })}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default WhyJoinTheFight
