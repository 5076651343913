import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import {StaticImage} from 'gatsby-plugin-image'
import Title from '../../../components/Title'
import Text from '../../../components/Text'
import {section} from './Jointhefight.module.scss'

const JoinTheFight: React.FC = () => {
  return (
    <section id="jointhefight" className={section}>
      <Container>
        <Row>
          <Col
            lg={5}
            md={12}
            sm={12}
            className="ps-lg-5 pt-5 pb-0 pb-lg-3 position-relative"
          >
            <div className="py-4">
              <Title color="#fff" size="4vw">
                <h1>JOIN THE FIGHT</h1>
                <h1>AGAINST CERTAIN</h1>
                <h1>HPV CANCERS</h1>
              </Title>
            </div>
            <div className="pe-2">
              <Text>
                HPV (human papillomavirus) vaccination is available on the NHS
                in schools for all children in year 8/S1 as part of the National
                Immunisation Programme (NIP).
              </Text>
              <Text>
                Website developed, funded and provided by MSD. It is intended to
                provide general educational information and does not take the
                place of professional medical advice.
              </Text>
            </div>
          </Col>
          <Col lg={7} md={12} sm={12} className="d-flex align-items-end">
            <div>
              <StaticImage
                alt="Boy and Girl supporting HPV Wise vaccination"
                src={'../../../assets/images/pngs/HPV-Boy-and-Girl.png'}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default JoinTheFight
