import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Text from '../../../components/Text'
import Video from '../../../components/Video'
import {VIDEO_URL} from '../../../constants'

const VaccinationProgramme: React.FC = () => (
  <section id="vaccination-programme">
    <Container className="d-flex flex-column justify-content-center align-content-center py-5">
      <Row className="pt-3 pb-2">
        <Col xs={{span: 10, offset: 1}} md={10}>
          <Text color="#4a4a4a" customClassName="fs-4">
            Protect your child now to help reduce their risk of certain HPV
            cancers in the future. Watch the video below to learn more:
          </Text>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col>
          <Video
            title="HPV Vaccine presentation"
            videoUrl={VIDEO_URL.PARENTS}
          />
        </Col>
      </Row>
    </Container>
  </section>
)

export default VaccinationProgramme
