import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import {Container, Row, Col} from 'react-bootstrap'
import Text from '../../../components/Text'

import {section} from './WorldWideVaccination.module.scss'

const WorldwideVaccination: React.FC = () => {
  return (
    <section className={section}>
      <Container>
        <Row className="pt-5 px-4">
          <Col lg={8} className="px-lg-5 pb-lg-5 pb-md-0">
            <div className="d-flex mb-5">
              <StaticImage
                src="./img/worldwide-vaccine-desktop.png"
                alt="HPV vaccination statistics"
                className="d-none d-lg-block"
              />
              <StaticImage
                src="./img/worldwide-vaccine-mobile.png"
                alt="HPV vaccination statistics"
                className="d-lg-none mx-auto"
                width={400}
              />
            </div>

            <Text color="#4e4e4e" customClassName="text-start">
              Your child can join the fight against certain HPV cancers by
              getting vaccinated against HPV as part of the National
              Immunisation Programme (NIP).
            </Text>
            <Text color="#4e4e4e" customClassName="text-start">
              HPV vaccination helps stop the spread of types of human
              papillomavirus, including high-risk types HPV 16 and HPV 18.
            </Text>
          </Col>
          <Col lg={4} className="d-flex ps-lg-0">
            <StaticImage
              src="../../../assets/images/pngs/Boy-2.png"
              alt="Boy supporting HPVWise vaccination"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default WorldwideVaccination
