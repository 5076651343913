import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {StaticImage} from 'gatsby-plugin-image'
import Text from '../../../components/Text'

import {imageBoxWithText, bgImage} from './hpvinfowithimage.module.scss'

interface SectionDataType {
  textData: Array<string>
  altText: string
}

const sectionData: Array<SectionDataType> = [
  {
    textData: [
      'Many people don’t know they’re infected with HPV because infections don’t usually cause any symptoms and most clear up on their own.',
      'Some types of HPV are called high-risk because they increase the risk of cancers if an infection lasts a long time. HPV 16 and HPV 18 are examples of high-risk viruses and cause most cases of anal cancer and cervical cancer.',
    ],
    altText: 'Group of boys in school uniform',
  },
  {
    textData: [
      'HPV vaccination also helps reduce the spread of types of human papillomavirus from one person to another during close skin-to-skin contact.',
      'The long-term safety profile of HPV vaccines has been well studied in millions of people worldwide.',
      'HPV vaccines are closely monitored to ensure they continue to be suitable for use.',
    ],
    altText: 'Girl encouraging HPV Vaccination',
  },
]

interface Props {
  dataId: number
}

const HPVInfoWithImage: React.FC<Props> = ({dataId}) => {
  const generateTextTags = (data: Array<string>): React.ReactNode => {
    return (
      <div className="mt-lg-4">
        {data.map((item, i) => (
          <Text color="#4e4e4e" key={i} customClassName="text-start">
            {item}
          </Text>
        ))}
      </div>
    )
  }

  const {altText} = sectionData[dataId]

  return (
    <section className={imageBoxWithText}>
      <Container>
        {dataId === 0 ? (
          <Row className="ps-lg-5 py-5 px-4">
            <Col lg={6} className="ps-lg-5 mt-5 order-last order-lg-first">
              {generateTextTags(sectionData[dataId].textData)}
            </Col>
            <Col lg={6}>
              <StaticImage
                src="../../../assets/images/jpegs/students.jpeg"
                height={350}
                alt={altText}
                className={bgImage}
              />
            </Col>
          </Row>
        ) : (
          <Row className="py-5 px-4">
            <Col lg={6}>
              <StaticImage
                src="../../../assets/images/jpegs/girlWithBlurredBg.jpeg"
                height={350}
                alt={altText}
              />
            </Col>
            <Col lg={6} className="px-lg-5 mt-5">
              {generateTextTags(sectionData[dataId].textData)}
            </Col>
          </Row>
        )}
      </Container>
    </section>
  )
}

export default HPVInfoWithImage
