import React from 'react'
import {Link} from 'gatsby'
import {Button} from 'react-bootstrap'
import {btnLink} from './linkbutton.module.scss'

interface Props {
  link: string
  children: React.ReactNode
  className?: string
}

const LinkButton = ({link, children, className = ''}: Props) => {
  return (
    <Link to={link} className={className}>
      <Button className={btnLink}>{children}</Button>
    </Link>
  )
}

export default LinkButton
