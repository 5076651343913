import React from 'react'
import {Row, Col, Image, Container} from 'react-bootstrap'
import {
  card,
  cardTitle,
  cardText,
  wrapper,
  cardContainer,
  cardDescription,
} from './CardsWithIcons.module.scss'

import HPVicon01 from '../../../assets/images/svgs/HPV-icon-01.svg'
import HPVicon02 from '../../../assets/images/svgs/HPV-icon-02.svg'
import HPVicon03 from '../../../assets/images/svgs/HPV-icon-03.svg'
import HPVicon04 from '../../../assets/images/svgs/HPV-icon-04.svg'

const cards = [
  {
    id: 'analCancers',
    iconPath: HPVicon01,
    altText: 'Picture of anal cancer in the intestine',
    title: 'Over 80%',
    text: 'of ANAL CANCERS worldwide are caused by HPV types 16 and 18',
  },
  {
    id: 'cervicalCancers',
    iconPath: HPVicon02,
    altText: 'Picture of cervical cancer in the uterus',
    title: 'OVER 75%',
    text: 'of CERVICAL CANCERS worldwide are caused by HPV types 16 and 18',
  },
  {
    id: 'hpvInfections',
    iconPath: HPVicon03,
    altText: 'Picture of HPV infection with magnifying glass',
    title: '2 YEARS',
    text: 'Most HPV infections don’t cause symptoms and clear up within two years',
  },
  {
    id: 'hpvCancers',
    iconPath: HPVicon04,
    altText: 'Picture of the HPV virus with gender symbols',
    title: '+20 YEARS',
    text: 'An infection caused by a high-risk human papillomavirus can take about 20 years or more to turn into types of HPV-related cancer',
  },
]

const CardsWithIcons: React.FC = () => {
  return (
    <section id="cardsWithIcons">
      <Container>
        <Row className={`${cardContainer} py-lg-0`}>
          {cards.map((item) => (
            <Col key={item.id} sm={10} md={8} lg={3} className={wrapper}>
              <div className={card}>
                <Image src={item.iconPath} alt={item.altText} width={120} />
                <div className={cardDescription}>
                  <h3 className={cardTitle}>{item.title}</h3>
                  <p className={cardText}>{item.text}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default CardsWithIcons
