// extracted by mini-css-extract-plugin
export var imageDesktop = "IntroductionSection-module--image-desktop--f75f8";
export var imageMobile = "IntroductionSection-module--image-mobile--40fa5";
export var introductionColumnLeft = "IntroductionSection-module--introduction-column-left--57d4b";
export var introductionColumnRight = "IntroductionSection-module--introduction-column-right--0c8d7";
export var introductionContainer = "IntroductionSection-module--introduction-container--f3343";
export var introductionLeftImage = "IntroductionSection-module--introduction-left-image--275f2";
export var introductionRightImage = "IntroductionSection-module--introduction-right-image--bee9a";
export var introductionSection = "IntroductionSection-module--introduction-section--e680e";
export var introductionText = "IntroductionSection-module--introduction-text--c5412";
export var introductionTextContainer = "IntroductionSection-module--introduction-text-container--c3171";
export var paragraphCenter = "IntroductionSection-module--paragraph-center--a39a9";
export var paragraphFirst = "IntroductionSection-module--paragraph-first--545c5";
export var paragraphLast = "IntroductionSection-module--paragraph-last--8c9d7";