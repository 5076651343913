import React from 'react'
import {video, videoProperties} from './Video.module.scss'

interface Props {
  videoUrl: string
  title: string
}
const Video = ({videoUrl, title}: Props) => (
  <div className={video}>
    <iframe
      src={videoUrl}
      title={title}
      allow="encrypted-media"
      allowFullScreen
      className={videoProperties}
    />
  </div>
)

export default Video
