import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import {StaticImage} from 'gatsby-plugin-image'
import Title from '../../../components/Title'
import Text from '../../../components/Text'
import {howToJoinTheFightSection} from './howtojointhefight.module.scss'

const HowToJoinTheFight: React.FC = () => {
  return (
    <section id="HowToJoinTheFight" className={howToJoinTheFightSection}>
      <Container>
        <Row className="pt-5 px-4">
          <Col lg={6} className={`text-center order-last order-lg-first`}>
            <div className="d-flex align-items-end">
              <StaticImage
                src="../../../assets/images/pngs/HPV-Boy-and-Girl-3.png"
                alt="A image of boy and girl supporting HPV vaccination"
              />
            </div>
          </Col>
          <Col lg={6} className="px-lg-5 pb-3">
            <Title color="#fff" size="2rem">
              <h2 className="text-start">
                HOW TO JOIN THE FIGHT AGAINST CERTAIN HPV CANCERS
              </h2>
            </Title>
            {data.map((item) => (
              <Text key={item.text}>{item.text}</Text>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HowToJoinTheFight

const data = [
  {
    type: 'text',
    text: 'Boys and girls aged 12 to 13 years (11 to 13 years in Scotland) are offered the HPV (human papillomavirus) vaccination during school year 8/S1 (this is often around September time).',
  },
  {
    type: 'text',
    text: "Look out for more information from your child's school.",
  },
  {
    type: 'text',
    text: 'If you would like any further information about the NHS National Immunisation Programme, or if you think your child has missed their vaccination, please speak to your school nurse or other healthcare professional.',
  },
]
