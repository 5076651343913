import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import {StaticImage} from 'gatsby-plugin-image'

import {URL} from '../../../constants'

import LinkButton from '../../../components/LinkButton'

import {section} from './QuestionsAnswered.module.scss'

const QuestionsAnswered: React.FC = () => (
  <section id="questionsAnswered" className={section}>
    <Container>
      <Row>
        <Col
          md={5}
          lg={6}
          className="d-flex justify-content-center align-items-center"
        >
          <LinkButton link={URL.FAQS} className="my-5">
            CLICK HERE HAVE YOUR HPV QUESTIONS ANSWERED
          </LinkButton>
        </Col>
        <Col md={7} lg={6} className={''}>
          <StaticImage
            src="../../../assets/images/pngs/Girl2.png"
            alt="Photograph of girl flexing her arm"
            width={500}
          />
        </Col>
      </Row>
    </Container>
  </section>
)

export default QuestionsAnswered
