import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import {Col, Container, Row} from 'react-bootstrap'

import {
  imageDesktop,
  imageMobile,
  introductionColumnLeft,
  introductionColumnRight,
  introductionContainer,
  introductionLeftImage,
  introductionRightImage,
  introductionSection,
  introductionText,
  introductionTextContainer,
  paragraphCenter,
  paragraphFirst,
  paragraphLast,
} from './IntroductionSection.module.scss'

const IntroductionSection: React.FC = () => {
  return (
    <section id="introductionSection" className={introductionSection}>
      <Container>
        <div className={introductionContainer}>
          <Row>
            <Col className={introductionColumnLeft} lg={7}>
              <StaticImage
                src="../../../assets/images/svgs/dotted-icons-desktop.svg"
                alt="Icons for circle with stars, calendar and syringe"
                className={`${imageDesktop} ${introductionLeftImage}`}
              />
              <StaticImage
                src="../../../assets/images/svgs/dotted-icons-mobile.svg"
                alt="Icons for circle with stars, calendar and syringe"
                className={`${imageMobile} ${introductionLeftImage}`}
              />
              <div className={introductionTextContainer}>
                <Row>
                  <Col>
                    <p className={`${introductionText} ${paragraphFirst}`}>
                      HPV-related cancers develop when the immune system can’t
                      clear up infections caused by types of high-risk human
                      papillomaviruses.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className={`${introductionText} ${paragraphCenter}`}>
                      Those HPV infections may cause changes in cells that
                      increase the risk of certain cancers and pre-cancerous
                      lesions in the future, like cervical cancer and anal
                      cancer.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className={`${introductionText} ${paragraphLast}`}>
                      Vaccination can help reduce the risk of your son or
                      daughter developing certain HPV cancers in the future by
                      protecting them against types of high-risk human
                      papillomaviruses.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className={introductionColumnRight} lg={5}>
              <StaticImage
                src="../../../assets/images/pngs/boy-1.png"
                alt="Boy in white shirt facing left and looking up"
                className={introductionRightImage}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default IntroductionSection
