import React from 'react'
import type {NavLink} from '../components/Layout'
import {Header, Footer} from '../components/Layout'

import CardsWithIcons from '../sections/parents/CardsWithIcons'
import VaccinationProgramme from '../sections/parents/VaccinationProgramme'
import HowToJoinTheFight from '../sections/parents/HowToJoinTheFight'
import HPVInfoWithImage from '../sections/parents/HPVInfoWithImage'
import IntroductionSection from '../sections/parents/IntroductionSection'
import JoinTheFight from '../sections/parents/JoinTheFight'
import QuestionsAnswered from '../sections/parents/QuestionsAnswered'
import WhyJoinTheFight from '../sections/parents/WhyJoinTheFight'
import WorldwideVaccination from '../sections/parents/WorldwideVaccination'
import SEO from '../components/SEO'
import {URL} from '../constants'
import {PageProps} from 'gatsby'

const pageCertificate = {
  jobCode: 'GB-HPV-00209',
  dateOfPrep: 'May 2023',
}

const NAV_LINKS: NavLink[] = [
  {
    text: 'Information for teenagers',
    url: URL.TEENAGERS,
  },
  {text: 'FAQs', url: URL.FAQS},
]

const HomePage = (props: PageProps) => {
  return (
    <>
      <SEO />
      <Header navLinks={NAV_LINKS} />
      <main className="content overflow-hidden">
        <JoinTheFight />
        <IntroductionSection />
        <WhyJoinTheFight />
        <HPVInfoWithImage dataId={0} />
        <CardsWithIcons />
        <WorldwideVaccination />
        <HowToJoinTheFight />
        <VaccinationProgramme />
        <HPVInfoWithImage dataId={1} />
        <QuestionsAnswered />
      </main>
      <Footer {...pageCertificate} />
    </>
  )
}

export default HomePage
